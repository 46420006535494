<template>
  <div>
    <div class="m-b-20">
      <Divider dashed>类型汇总</Divider>
      <Table
        size="small"
        stripe
        :data="typeSummaryList"
        :columns="typeColumns"
      ></Table>
    </div>
    <div class="m-b-20">
      <Divider dashed>施工单位</Divider>
      <Table
        size="small"
        stripe
        :data="supplierSummaryList"
        :columns="supplierColumns"
      ></Table>
    </div>
    <div class="m-b-20">
      <Divider dashed>位置汇总</Divider>
      <Table
        size="small"
        stripe
        :data="poistionSummaryList"
        :columns="positionColumns"
      ></Table>
    </div>
  </div>
</template>

<script>
import { getTaskitemGather } from '@/api/msp/workdayTable'
export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      typeSummaryList: [],
      supplierSummaryList: [],
      poistionSummaryList: [],
      typeColumns: [
        { title: '任务', key: 'name' },
        { title: '数量', align: 'right', key: 'value' }
      ],
      supplierColumns: [
        { title: '单位', key: 'name' },
        { title: '数量', align: 'right', key: 'value' }
      ],
      positionColumns: [
        { title: '位置', key: 'name' },
        { title: '数量', align: 'right', key: 'value' }
      ]
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      this.typeSummaryList = await this.getListData(1)
      this.supplierSummaryList = await this.getListData(2)
      this.poistionSummaryList = await this.getListData(3)
    },
    async getListData (type) {
      const result = await getTaskitemGather({ tableId: this.id, type })
      return result
    }
  }
}
</script>
